import { handleCall } from './handleResponse'

import {
  isOperatorManager,
  isSupervisor,
  isTechnician,
} from '../helpers/roles'

export const incidentsService = {
  getAll,
  getById,
  create,
  addNote,
  closeIncident,
  getActiveManualSwapsForLogged,
}

function getAll(dateRange) {
  const { since, until } = dateRange || { since: null, until: null }
  const filterDate = since && until ? `?since=${since}&until=${until}` : ''

  let getTypeByRole = '/GetForAdmin'
  if (isTechnician()) getTypeByRole = '/GetForTechnician'
  // if (isManager()) getTypeByRole = "/GetForAdmin";
  if (isSupervisor()) getTypeByRole = '/GetForSupervisor'
  if (isOperatorManager()) getTypeByRole = '/GetForOperatorUser'

  return handleCall(`/stations/v1/Tickets${getTypeByRole}${filterDate}`, {
    method: 'GET',
    body: null,
  })
}

function addNote(body) {
  const { id } = body
  delete body.id
  return handleCall(`/stations/v1/Tickets/${id}/AddNote`, {
    method: 'PUT',
    body: JSON.stringify(body),
  })
}

function closeIncident(body) {
  const { id } = body
  delete body.id
  return handleCall(`/stations/v1/Tickets/${id}/Close`, {
    method: 'PUT',
    body: JSON.stringify(body),
  })
}

function create(body) {
  return handleCall(`/stations/v1/Tickets/Create`, {
    method: 'POST',
    body: JSON.stringify(body),
  })
}

function getActiveManualSwapsForLogged(id) {
  return handleCall(`/stations/v1/Tickets/GetActiveManualSwapsForLogged`, {
    method: 'GET',
    body: null,
  })
}

function getById(id) {
  return handleCall(`/stations/v1/Tickets/${id}`, {
    method: 'GET',
    body: null,
  })
}
