import React, { useEffect, useState } from 'react'

import useStyles from './styles'

import DetailsContanierTable from './detailsContainerTable'
import AssignmentManager from './dialogs/assignmentManager'
import Create from './dialogs/create'
import MoreInformation from './dialogs/moreInformation'
import SwapList from './dialogs/swapList'

import { useTranslation } from 'react-i18next'
import CustomMaterialTable from '../shared/customMaterialTable'

import { connect } from 'react-redux'
import { batteriesActions } from '../../redux/actions/batteries_actions'
import { operatorActions } from '../../redux/actions/operator_action'
import { zonesActions } from '../../redux/actions/zones_actions'

import { displayWithTooltip } from '../shared/enshortenLargeData'
import { formatDateToDisplay } from '../shared/formatDate'

import {
  isCauUser,
  isFinancial,
  isManager,
  isManagerReadOnly,
  isCountryAdmin,
  isOperatorManager,
  isSacUser,
  isSupervisor,
  isTechnician,
} from '../../helpers/roles'

import i18next from 'i18next'
import { exportCsv } from '../../helpers/exportCsv'
import { handleTrueFalsyTermSwitch } from '../../helpers/filters'
import { stationsActions } from '../../redux/actions/stations_actions'
import ModalConfirm from '../shared/modalconfirm'
import ChangeDeliveryPoint from './dialogs/changeDeliveryPoint'

const BatteriesView = ({
  batteriesReducer,
  getAllBatteries,
  deletebat,
  getBatteryById,
  getAllZones,
  getAllOperators,
  getForTechnician,
  zonesReducer,
  operatorReducer,
  getForLogged,
  checkBattery,
  getDeliveryPoints,
  stationsReducer,
}) => {
  const { t } = useTranslation()
  const classes = useStyles()

  const [localData, setLocalData] = useState([])
  const [isDialogRemove, setIsDialogRemove] = useState(false)
  const [selectedData, setSelectedData] = useState({})
  const [assignMnmgtSelection, setAssignMnmgtSelection] = useState(false)
  const [isAssignmentManagerDialogOpen, setIsAssignmentManagerDialogOpen] =
    useState(false)
  const [isAddBatteriesDialogOpen, setIsAddBatteriesDialogOpen] =
    useState(false)
  const [isSwapListDialogOpen, setIsSwapListDialogOpen] = useState(false)
  const [isMoreInformationDialogOpen, setIsMoreInformationDialogOpen] =
    useState(false)
  const [isBatteryDetailsDialogOpen, setIsBatteryDetailsDialogOpen] =
    useState(false)
  const [isCheckModalOpen, setIsCheckModalOpen] = useState(false)
  const [isUncheckModalOpen, setIsUncheckModalOpen] = useState(false)
  const [isChangeDeliveryPointModalOpen, setIsChangeDeliveryPointModalOpen] =
    useState(false)

  useEffect(() => {
    const setData =
      isManager() ||
      isSacUser() ||
      isCauUser() ||
      isOperatorManager() ||
      isCountryAdmin() ||
      isFinancial() ||
      isSupervisor() ||
      isTechnician() ||
      isManagerReadOnly()
        ? batteriesReducer.results
        : batteriesReducer.batteriesAssignedToOperator

    let updatedList = setData.map((i) => {
      return {
        ...i,
        operatorData: operatorReducer.results.find(
          (n) => n.id === i?.batteryStation?.operatorCiaId,
        ),
        zoneData: zonesReducer.results.find(
          (n) => i?.batteryStation?.zoneId === n.id,
        ),
      }
    })

    setLocalData(updatedList)
  }, [
    batteriesReducer.results,
    batteriesReducer.batteriesAssignedToOperator,
    zonesReducer.results,
    operatorReducer.results,
  ])

  useEffect(() => {
    getForLogged()
    getAllZones()
    isTechnician() ? getForTechnician() : getAllOperators()
    getDeliveryPoints()
  }, [getAllOperators, getAllZones, getDeliveryPoints, getForLogged])

  const handleAssignmentManager = (rowData) => {
    setSelectedData(rowData)
    setIsAssignmentManagerDialogOpen(true)
  }

  const handleCloseAssignManagement = () => {
    setIsAssignmentManagerDialogOpen(false)
    setAssignMnmgtSelection(false)
    setLocalData(
      isManager() || isSacUser()
        ? batteriesReducer.results
        : batteriesReducer.batteriesAssignedToOperator,
    )
  }

  const handleClickScooterAcction = (rowData) => {
    setSelectedData(rowData)
    getBatteryById(rowData.id)
  }
  const handleAction = (rowData, action) => {
    rowData && setSelectedData(rowData)
    switch (action) {
      case 'remove':
        setIsDialogRemove(true)
        break
      case 'details':
        setIsBatteryDetailsDialogOpen(true)
        handleClickScooterAcction(rowData)
        break
      case 'check':
        setIsCheckModalOpen(true)
        break
      case 'uncheck':
        setIsUncheckModalOpen(true)
        break
      case 'changeDelivery':
        setIsChangeDeliveryPointModalOpen(true)
        setSelectedData(rowData)
        break
      default:
        break
    }
  }

  return (
    <div className={classes.batteryContainer}>
      <CustomMaterialTable
        saveFilters={true}
        autoclickSelected={true}
        rowClickAvaiable={true}
        isLoading={
          batteriesReducer.loadingResults ||
          zonesReducer.loadingResults ||
          operatorReducer.loadingResults
        }
        onRowClick={(rowData) => handleClickScooterAcction(rowData)}
        selection={assignMnmgtSelection}
        data={localData}
        title={t('batteries.page.table.title')}
        exportButton={!isFinancial() && !isSacUser() && !isTechnician()}
        exportCsv={exportCsv}
        columns={[
          {
            title: t('batteries.page.table.columns.country'),
            field: 'zoneName',
          },
          {
            title: t('batteries.page.table.columns.batteryPackId'),
            tooltip: t('batteries.page.table.columns.batteryPackIdTooltip'),
            field: 'batteryPackId',
          },
          {
            title: t('batteries.page.table.columns.counter'),
            tooltip: t('batteries.page.table.columns.counterTooltip'),
            field: 'dischargedKwhsCounter',
          },
          {
            title: t('batteries.page.table.columns.lastPercentBattery'),
            tooltip: t(
              'batteries.page.table.columns.lastPercentBatteryTooltip',
            ),
            field: 'soC',
            render: (rowData) => {
              return rowData.soC != null ? rowData.soC + '%' : ''
            },
          },
          {
            title: t('batteries.page.table.columns.lastConnection'),
            tooltip: t('batteries.page.table.columns.lastConnectionTooltip'),
            field: 'lastConnectionTimestamp',
            render: (rowData) =>
              formatDateToDisplay(rowData?.unifiedDynamic?.lastUpdateSoc, true),
          },
          {
            title: t('batteries.page.table.columns.dealerLoc'),
            render: (rowData) => rowData.location,
            lookup: {
              0: t('batteries.page.table.columns.customer'),
              1: t('batteries.page.table.columns.deliveryPoint'),
              2: t('batteries.page.table.columns.station'),
            },
            customFilterAndSearch: (term, rowData) => {
              return (
                term.length === 0 ||
                (term.includes('0') && rowData.location === 'Cliente') ||
                (term.includes('1') &&
                  rowData.location === 'Punto de entrega') ||
                (term.includes('2') && rowData.location === 'Estación')
              )
            },
          },
          {
            title: t('batteries.page.table.columns.dealerDisplayName'),
            field: 'locationDisplayName',
            render: (rowData) =>
              rowData.locationDisplayName
                ? displayWithTooltip(rowData.locationDisplayName)
                : '',
            exportFn: (rowData) => rowData.locationDisplayName,
          },
          {
            title: t('batteries.page.table.columns.customerId'),
            field: 'customerId',
          },
          {
            title: t('batteries.page.table.columns.contractId'),
            field: 'contractId',
          },
          {
            title: t('batteries.page.table.columns.checked'),
            field: 'batteryStation.accountingCheck',
            render: (rowData) =>
              t(
                `batteries.page.table.actions.${
                  rowData.batteryStation.accountingCheck
                    ? 'registered'
                    : 'unregistered'
                }`,
              ),
            onClick: (_, rowData) => handleAction(rowData, 'check'),
            lookup: {
              0: t('batteries.page.table.actions.registered'),
              1: t('batteries.page.table.actions.unregistered'),
            },
            customFilterAndSearch: (term, rowData) =>
              handleTrueFalsyTermSwitch(
                term,
                rowData.batteryStation.accountingCheck,
              ),
          },
          {
            title: t('batteries.page.table.columns.stolen'),
            field: 'stolenTimestamp',
            render: (rowData) =>
              rowData.isStolen
                ? formatDateToDisplay(rowData?.stolenTimestamp, true)
                : '',
            lookup: {
              0: t('batteries.page.table.actions.yes'),
              1: t('batteries.page.table.actions.no'),
            },
            customFilterAndSearch: (term, rowData) =>
              term.length === 0 ||
              (term[0] === '0' && rowData.isStolen) ||
              (term[0] === '1' && !rowData.isStolen),
          },
        ]}
        actions={[
          {
            icon: 'add',
            tooltip: t('zones.page.table.actions.add'),
            isFreeAction: true,
            onClick: (event, rowData) => setIsAddBatteriesDialogOpen(true),
            hidden: !isManager() && !isSacUser(),
          },
          {
            icon: 'gavel',
            tooltip: t('batteries.page.table.actions.activateAssignation'),
            isFreeAction: true,
            onClick: (event, rowData) =>
              setAssignMnmgtSelection((prev) => !prev),
            hidden: !isManager(),
          },
          {
            icon: 'assignment',
            tooltip: t('batteries.page.table.actions.openAssignation'),
            onClick: (event, rowData) => {
              handleAssignmentManager(rowData)
            },
            hidden: !assignMnmgtSelection || !isManager() || !isSacUser(),
          },
          {
            icon: 'dehaze',
            tooltip: t('batteries.page.table.actions.details'),
            onClick: (event, rowData) => {
              handleAction(rowData, 'details')
            },
            hidden: isFinancial()
          },
          {
            icon: 'delete',
            tooltip: t('batteries.page.table.actions.delete'),
            onClick: (event, rowData) => handleAction(rowData, 'remove'),
            hidden: assignMnmgtSelection || !isManager(),
          },
          (rowData) => ({
            icon: 'check',
            tooltip: t(`batteries.page.table.actions.check`),
            onClick: (event, rowData) => handleAction(rowData, 'check'),
            hidden:
              !(isManager() || isFinancial()) ||
              rowData.batteryStation?.accountingCheck,
          }),
          (rowData) => ({
            icon: 'dangerous',
            tooltip: t(`batteries.page.table.actions.uncheck`),
            onClick: (event, rowData) => handleAction(rowData, 'uncheck'),
            hidden:
              !(isManager() || isFinancial()) ||
              !rowData.batteryStation?.accountingCheck,
          }),
          (rowData) => ({
            icon: 'create',
            tooltip: t(`batteries.page.table.actions.changeDelivery`),
            onClick: (event, rowData) =>
              handleAction(rowData, 'changeDelivery'),
            hidden: !(
              (isManager() || isSacUser() || isSupervisor() || isTechnician()) &&
              rowData.location === 'Punto de entrega'
            ),
          }),
        ]}
      />
      <AssignmentManager
        selectedData={selectedData}
        isOpen={isAssignmentManagerDialogOpen}
        onClose={handleCloseAssignManagement}
      />
      <Create
        isOpen={isAddBatteriesDialogOpen}
        onClose={() => setIsAddBatteriesDialogOpen(false)}
      />
      <SwapList
        selectedData={selectedData}
        isOpen={isSwapListDialogOpen}
        onClose={() => setIsSwapListDialogOpen(false)}
      />
      <MoreInformation
        selectedData={selectedData}
        isOpen={isMoreInformationDialogOpen}
        onClose={() => setIsMoreInformationDialogOpen(false)}
      />{' '}
      <ModalConfirm
        isOpen={isDialogRemove}
        onClose={() => setIsDialogRemove(false)}
        onConfirm={() => deletebat(selectedData.id)}
        title={
          i18next.t('batteries.page.table.modal.title') +
          (selectedData && selectedData.batteryPackId)
        }
        body={i18next.t('batteries.page.table.modal.body')}
      />
      <ModalConfirm
        isOpen={isCheckModalOpen}
        onClose={() => setIsCheckModalOpen(false)}
        onConfirm={() => checkBattery(selectedData.id, true)}
        title={
          i18next.t('batteries.page.table.modalCheck.title') +
          '(' +
          (selectedData && selectedData.batteryPackId) +
          ')'
        }
        body={i18next.t('batteries.page.table.modalCheck.body')}
      />
      <ModalConfirm
        isOpen={isUncheckModalOpen}
        onClose={() => setIsUncheckModalOpen(false)}
        onConfirm={() => checkBattery(selectedData.id, false)}
        title={
          i18next.t('batteries.page.table.modalUncheck.title') +
          '(' +
          (selectedData && selectedData.batteryPackId) +
          ')'
        }
        body={i18next.t('batteries.page.table.modalUncheck.body')}
      />
      <ChangeDeliveryPoint
        isOpen={isChangeDeliveryPointModalOpen}
        onClose={() => setIsChangeDeliveryPointModalOpen(false)}
        deliveryPoints={stationsReducer.deliveriesForAdmin}
        batteryData={selectedData}
      />
      <DetailsContanierTable
        isOpen={isBatteryDetailsDialogOpen}
        onClose={() => setIsBatteryDetailsDialogOpen(false)}
        onOpenSwapList={() => setIsSwapListDialogOpen(true)}
        onOpenMoreInformation={() => setIsMoreInformationDialogOpen(true)}
      />
    </div>
  )
}

function mapState(state) {
  const { batteriesReducer, zonesReducer, operatorReducer, stationsReducer } =
    state
  return { batteriesReducer, zonesReducer, operatorReducer, stationsReducer }
}
const actionCreators = {
  getAllBatteries: batteriesActions.getAllBatteries,
  getBatteryById: batteriesActions.getBatteryById,
  setOutOfService: batteriesActions.setOutOfService,
  deletebat: batteriesActions.deletebat,
  getForLogged: batteriesActions.getForLogged,
  checkBattery: batteriesActions.setChecked,
  getAllZones: zonesActions.getAllZones,
  getAllOperators: operatorActions.getAllOperators,
  getDeliveryPoints: stationsActions.getToBatteriesDeliveryForAdmin,
  getForTechnician: operatorActions.getForTechnician,
}

export default connect(mapState, actionCreators)(BatteriesView)
