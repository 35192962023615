import AppBar from '@material-ui/core/AppBar'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import Divider from '@material-ui/core/Divider'
import Fab from '@material-ui/core/Fab'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Paper from '@material-ui/core/Paper'
import Slide from '@material-ui/core/Slide'
import TextField from '@material-ui/core/TextField'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import CloseIcon from '@material-ui/icons/Close'
import SendIcon from '@material-ui/icons/Send'
import moment from 'moment'
import React, { Fragment, useState } from 'react'

import { useTranslation } from 'react-i18next'

import { connect } from 'react-redux'
import { incidentsActions } from '../../../redux/actions/incidents_actions'

import modalConstants from '../../../helpers/modalConstants'
import ModalConfirm from '../../shared/modalconfirm'
import {
  isManager,
  isSupervisor,
  isCountryAdmin,
  isOperatorManager,
  isTechnician,
} from '../../../helpers/roles'

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },

  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },

  columnComments: {
    width: '70%',
  },
  columnForm: {
    width: '30%',
    paddingLeft: '1%',
  },
  dialogContainer: {
    padding: '1%',
  },
  details: {
    display: 'flex',
  },

  chatSection: {
    width: '100%',
    height: '80vh',
  },
  headBG: {
    backgroundColor: '#e0e0e0',
  },
  borderRight500: {
    borderRight: '1px solid #e0e0e0',
  },
  messageArea: {
    height: '60vh',
    overflowY: 'auto',
    width: '100%',
  },
}))

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

const IncidentDetails = ({
  isOpen,
  onClose,
  type,
  selectedRow,
  getById,
  incidentsReducer,
  addNote,
  closeIncident,
}) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const { incident } = incidentsReducer
  const [isConfirmCloseIncidentOpen, setisConfirmCloseIncidentOpen] =
    useState(false)

  const [note, setnote] = useState('')
  const handleClose = () => {
    onClose()
  }

  const handleCloseIncident = () => {
    setisConfirmCloseIncidentOpen(true)
  }

  const handleCloseIncidentConfirmed = (noteInput) => {
    closeIncident({
      id: selectedRow.id,
      note: noteInput,
    })
    onClose()
  }
  const handleEnter = () => {
    // getById(selectedRow.id);
  }

  const handleAddNote = () => {
    const bodyAddNote = {
      id: selectedRow.id,
      note,
    }
    setnote('')
    addNote(bodyAddNote)
  }

  const formatDate = (date) =>
    moment(date).format('DD/MM/YYYY') === 'Invalid date'
      ? '-- --'
      : moment(date).format('DD/MM/YYYY')

  const canAddNotes = isManager() || isSupervisor() || isOperatorManager() || isCountryAdmin() || isTechnician()

  return (
    <Dialog
      fullScreen
      open={isOpen}
      onClose={handleClose}
      onEnter={handleEnter}
      TransitionComponent={Transition}
    >
      <AppBar className={classes.appBar}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            {type === 'row'
              ? t('incidents.dialogs.details.dialogTitle.rows')
              : t('incidents.dialogs.details.dialogTitle.batteries')}
            (row/battery name)
          </Typography>
          {!selectedRow.isClosed && !isOperatorManager() && (
            <Button
              autoFocus
              color="inherit"
              onClick={handleCloseIncident}
              disabled={!selectedRow.isOpen}
            >
              {t('incidents.dialogs.details.closeInciden')}
            </Button>
          )}
        </Toolbar>
      </AppBar>
      <div className={classes.dialogContainer}>
        <div className={classes.details}>
          <div className={classes.columnComments}>
            <div>
              <Grid container component={Paper} className={classes.chatSection}>
                <List className={classes.messageArea}>
                  <ListItem key="1">
                    <Grid container>
                      {incident.notes &&
                        Array.isArray(incident.notes) &&
                        incident.notes.map((i) => {
                          return (
                            <Fragment>
                              <Grid item xs={12}>
                                <ListItemText
                                  align="right"
                                  primary={i.text}
                                ></ListItemText>
                              </Grid>
                              <Grid item xs={12}>
                                <ListItemText
                                  align="right"
                                  secondary={moment(i.created).format(
                                    'DD/MM/YYYY h:mma',
                                  )}
                                ></ListItemText>
                              </Grid>
                            </Fragment>
                          )
                        })}
                    </Grid>
                  </ListItem>
                </List>
                <Divider />
                {canAddNotes ? (
                  <Grid container style={{ padding: '20px' }}>
                    <Grid item xs={11}>
                      <TextField
                        id="outlined-basic-email"
                        label="Type Something"
                        fullWidth
                        value={note}
                        onChange={(e) => setnote(e.target.value)}
                      />
                    </Grid>
                    <Grid xs={1} align="right">
                      <Fab
                        color="primary"
                        aria-label="add"
                        onClick={handleAddNote}
                      >
                        <SendIcon />
                      </Fab>
                    </Grid>
                  </Grid>
                ) : null}
              </Grid>
            </div>
          </div>
          <div className={classes.columnForm}>
            <TextField
              label={t('incidents.dialogs.details.labels.incidentId')}
              fullWidth
              required
              value={selectedRow.id}
              inputProps={{ readOnly: true }}
            />
            {selectedRow.zoneName && (
              <TextField
                label={t('incidents.dialogs.details.labels.zone')}
                fullWidth
                required
                value={selectedRow.zoneName}
                inputProps={{ readOnly: true }}
              />
            )}
            {selectedRow.operatorCiaName && (
              <TextField
                label={t('incidents.dialogs.details.labels.operatorId')}
                fullWidth
                required
                value={selectedRow.operatorCiaName}
                inputProps={{ readOnly: true }}
              />
            )}
            {selectedRow.stationAddress && (
              <TextField
                label={t('incidents.dialogs.details.labels.station')}
                fullWidth
                required
                value={selectedRow.stationAddress}
                inputProps={{ readOnly: true }}
              />
            )}
            <TextField
              label={t('incidents.dialogs.details.labels.startDate')}
              fullWidth
              required
              value={formatDate(selectedRow.opened)}
              inputProps={{ readOnly: true }}
            />
            <TextField
              label={t('incidents.dialogs.details.labels.finishDate')}
              fullWidth
              required
              value={formatDate(selectedRow.closed)}
              inputProps={{ readOnly: true }}
            />
            <TextField
              label={t('incidents.dialogs.details.labels.state')}
              fullWidth
              required
              value={
                selectedRow.isOpen
                  ? t('incidents.page.table.columns.open')
                  : t('incidents.page.table.columns.close')
              }
              inputProps={{ readOnly: true }}
            />
            <TextField
              label={t('incidents.dialogs.details.labels.userOpenTicket')}
              fullWidth
              required
              value={selectedRow.customerEmail}
              inputProps={{ readOnly: true }}
            />
            {/* <TextField
                            label={t("incidents.dialogs.details.labels.userLastSwapOK")}
                            fullWidth
                            required
                            inputProps={{ readOnly: true }}
                        /> */}
            <TextField
              label={t('incidents.dialogs.details.labels.reason')}
              fullWidth
              required
              value={selectedRow.description}
              inputProps={{ readOnly: true }}
            />
          </div>
        </div>
      </div>
      <ModalConfirm
        isOpen={isConfirmCloseIncidentOpen}
        onClose={() => setisConfirmCloseIncidentOpen(false)}
        onConfirm={handleCloseIncidentConfirmed}
        title={t('incidents.dialogs.confirmClose.title')}
        label={'Comment'}
        disabledCondition={modalConstants.NON_EMPTY_INPUT}
        inputAvaiable={true}
      />
    </Dialog>
  )
}

function mapState(state) {
  const { incidentsReducer } = state
  return { incidentsReducer }
}
const actionCreators = {
  getById: incidentsActions.getById,
  addNote: incidentsActions.addNote,
  closeIncident: incidentsActions.closeIncident,
}

export default connect(mapState, actionCreators)(IncidentDetails)
