import React, { useEffect, useState } from 'react'

import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete'

import useStyles from './styles'

import GoogleMapReactCustom from '../shared/geofence/googleMapReact'

import { useTranslation } from 'react-i18next'
import StationDetails from '../stations/dialogs/details'

import { connect } from 'react-redux'
import { operatorActions } from '../../redux/actions/operator_action'
import { stationsActions } from '../../redux/actions/stations_actions'
import { uiActions } from '../../redux/actions/ui_actions'
import { zonesActions } from '../../redux/actions/zones_actions'

import {
  isCauUser,
  isManager,
  isSacUser,
  isTechnician,
  isManagerReadOnly,
  isSupervisor,
} from '../../helpers/roles'
import {
  getSupervisorDefaultZoneId,
} from '../../helpers/operator'

const MapView = ({
  zonesReducer,
  operatorReducer,
  getAllOperators,
  getAllZones,

  getAllStations,
  stationsReducer,
  getDynamicsById,
  getForLogged,
  getStationsAssignedToZone,
}) => {
  const { t } = useTranslation()
  const classes = useStyles()

  const [stationsOnDisplay, setstationsOnDisplay] = useState([])
  const [zoneFilter, setzoneFilter] = useState()
  const [operatorFilter, setoperatorFilter] = useState()

  useEffect(() => {
    setTimeout(() => {
      if (
        isManager() ||
        isManagerReadOnly() ||
        isSacUser() ||
        isCauUser() ||
        isSupervisor()
      )
        getAllOperators()

      if (isTechnician()) {
        getForLogged()
      } else if (isSupervisor()) {
        getStationsAssignedToZone(zoneFilter?.id ?? getSupervisorDefaultZoneId())
      } else {
        getAllStations()
      }
      getAllZones()
    }, 1000)
  }, [
    getAllOperators,
    getAllStations,
    getAllZones,
    getForLogged,
    getStationsAssignedToZone,
    zoneFilter,
  ])

  useEffect(() => {
    const { results, stationsAssignedToOperator, stationsAssignedToZone } = stationsReducer
    setstationsOnDisplay(
      isManager() || isManagerReadOnly() || isSacUser() || isCauUser() || isTechnician()
        ? results
        : isSupervisor() ? stationsAssignedToZone : stationsAssignedToOperator,
    )
  }, [
    stationsReducer,
    stationsReducer.results,
    stationsReducer.stationsAssignedToOperator,
    stationsReducer.stationsAssignedToZone,
  ])

  useEffect(() => {
    const { results, stationsAssignedToOperator, stationsAssignedToZone } = stationsReducer
    if (zoneFilter || operatorFilter) {
      setstationsOnDisplay(
        (isManager() || isManagerReadOnly() || isSacUser() || isCauUser() || isTechnician()
          ? results
          : isSupervisor() ? stationsAssignedToZone : stationsAssignedToOperator
        ).filter((i) => {
          return (
            i?.zoneId === zoneFilter?.id ||
            i?.operatorCiaId === operatorFilter?.id
          )
        }),
      )
    } else {
      setstationsOnDisplay(
        isManager() || isManagerReadOnly() || isSacUser() || isCauUser() || isTechnician()
          ? results
          : isSupervisor() ? stationsAssignedToZone : stationsAssignedToOperator,
      )
    }
  }, [zoneFilter, operatorFilter, stationsReducer])

  const [isStationDetailsDialogsOpen, setisStationDetailsDialogsOpen] =
    useState(false)
  const [selectedStation, setselectedStation] = useState({})

  const handleMarkerClick = (properties) => {
    const { stationData, stationId } = properties

    setselectedStation(stationData)
    setisStationDetailsDialogsOpen(true)
    getDynamicsById(stationId)
  }

  return (
    <div className={classes.pageContainer}>
      <Grid container spacing={1} className={classes.comboBoxContainer}>
        <Grid item xs={3}>
          <Autocomplete
            id="combo-box-demo"
            options={zonesReducer.results}
            getOptionLabel={(option) => option.name}
            // style={{ width: 300 }}
            value={zoneFilter}
            onChange={(event, value) => setzoneFilter(value)}
            renderInput={(params) => (
              <TextField
                {...params}
                label={t('map.page.labels.zones')}
                // variant="outlined"
              />
            )}
          />
        </Grid>
        <Grid item xs={3}>
          {(isManager() || isManagerReadOnly()) && (
            <Autocomplete
              id="combo-box-demo"
              options={operatorReducer.results}
              getOptionLabel={(option) => option.name}
              value={operatorFilter}
              onChange={(event, value) => setoperatorFilter(value)}
              // style={{ width: 300 }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={t('map.page.labels.operators')}
                  // variant="outlined"
                />
              )}
            />
          )}
        </Grid>
        <Grid item xs={6}></Grid>
      </Grid>
      <GoogleMapReactCustom
        onMarkerClick={handleMarkerClick}
        stations={stationsOnDisplay}
      />
      <StationDetails
        isOpen={isStationDetailsDialogsOpen}
        onClose={() => setisStationDetailsDialogsOpen(false)}
        selectedStation={selectedStation}
      />
    </div>
  )
}

function mapState(state) {
  const { uiReducer, zonesReducer, operatorReducer, stationsReducer } = state
  return { uiReducer, zonesReducer, operatorReducer, stationsReducer }
}

const actionCreators = {
  setDraggableDashoardIsOpen: uiActions.setDraggableDashoardIsOpen,
  getAllOperators: operatorActions.getAllOperators,
  getAllZones: zonesActions.getAllZones,
  getAllStations: stationsActions.getAllStations,
  getDynamicsById: stationsActions.getDynamicsById,
  getStationById: stationsActions.getStationById,
  getForLogged: stationsActions.getForLogged,
  getStationsAssignedToZone: stationsActions.getStationsAssignedToZone,
}

export default connect(mapState, actionCreators)(MapView)
