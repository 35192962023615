import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { isCauUser, isFinancial, isManager, isCountryAdmin, isOperatorManager, isSacUser, isSupervisor, isTechnician } from '../../../helpers/roles'
import { incidentsActions } from '../../../redux/actions/incidents_actions'
import { operatorActions } from '../../../redux/actions/operator_action'
import { stationsActions } from '../../../redux/actions/stations_actions'
import { swapsActions } from '../../../redux/actions/swaps_actions'
import { zonesActions } from '../../../redux/actions/zones_actions'
import { swapsService } from '../../../services/swapsServices'
import CustomMaterialTable from '../../shared/customMaterialTable'
import { displayWithTooltip } from '../../shared/enshortenLargeData'
import { formatDateToDisplay } from '../../shared/formatDate'
import Create from '../dialogs/create'
import CreateSwapManual from '../dialogs/createSwapManual'
import SwapDetails from './details'

const SwapSection = ({
  getAll,
  swapsReducer,
  getAllZones,
  getAllStations,
  getAllOperators,
  getForTechnician,
  getForLogged,
  getActiveManualSwapsForLogged,
}) => {
  const { t } = useTranslation()

  const [isSwapDetailsOpen, setIsSwapDetailsOpen] = useState(false)
  const [isDialogCreateOpen, setIsDialogCreateOpen] = useState(false)
  const [isDialogCreateManualOpen, setIsDialogCreateManualOpen] =
    useState(false)

  const [selectedManualSwapItem, setSelectedManualSwapItem] = useState({})
  const [resultManualSwapItemSwap, setResultManualSwapItemSwap] = useState({})

  useEffect(() => {
    getAll()
    getAllZones()
    isTechnician() ? getForLogged() : getAllStations()
    isTechnician() ? getForTechnician() : getAllOperators()
    getActiveManualSwapsForLogged()
  }, [
    getActiveManualSwapsForLogged,
    getAll,
    getAllOperators,
    getAllStations,
    getAllZones,
    getForLogged,
    getForTechnician,
  ])

  const handleSwap = (item) => {
    setSelectedManualSwapItem(item)
    swapsService.getForManualSwapTicketAsync(item.id).then(
      (result) => {
        setResultManualSwapItemSwap(result)
        setIsDialogCreateManualOpen(true)
      },
      () => {},
    )
  }

  return (
    <React.Fragment>
      <div>
        <CustomMaterialTable
          title={t('swaps.usage.table.title')}
          isLoading={swapsReducer.loadingResults}
          exportButton={!isTechnician() && !isCauUser() && !isSacUser() && !isFinancial()}
          actions={[
            {
              icon: 'add',
              tooltip: t('swaps.usage.table.actions.add'),
              isFreeAction: true,
              onClick: () => setIsDialogCreateOpen(true),
              disabled: isDialogCreateOpen,
              hidden: !isManager() && !isSacUser()
            },
            {
              icon: 'handshake',
              tooltip: t('swaps.usage.table.actions.addManual'),
              isFreeAction: true,
              onClick: () => setIsDialogCreateManualOpen(true),
              disabled: isDialogCreateManualOpen,
              hidden: !isManager() && !isSacUser() && !isTechnician() && !isSupervisor() && !isCountryAdmin() && !isOperatorManager(),
            },
            (rowData) => ({
              icon: 'assignment_return',
              tooltip: t('swapManual.page.table.actions.swap'),
              onClick: (_, rowData) => handleSwap(rowData),
              hidden:
                isCauUser() || rowData.swapType !== 1 || rowData.status === 2,
            }),
          ]}
          columns={[
            {
              title: t('swaps.usage.table.columns.dateTimeSwapPreformed'),
              tooltip: t(
                'swaps.usage.table.columns.dateTimeSwapPreformedTooltip',
              ),
              field: 'creationTimestamp',
              render: (rowData) =>
                formatDateToDisplay(rowData.creationTimestamp, true),
            },
            {
              title: t('swaps.usage.table.columns.status'),
              tooltip: t('swaps.usage.table.columns.statusTooltip'),
              field: 'status',
              lookup: {
                0: t('swaps.usage.labels.reserved'),
                1: t('swaps.usage.labels.retrying'),
                2: t('swaps.usage.labels.finalized'),
                3: t('swaps.usage.labels.cancelled'),
              },
              customFilterAndSearch: (term, rowData) => {
                return (
                  term.length === 0 || term.includes(rowData.status.toString())
                )
              },
              render: (rowData) => {
                switch (rowData.status) {
                  case 0:
                    return t('swaps.usage.table.values.reserved')
                  case 1:
                    return t('swaps.usage.table.values.retrying')
                  case 2:
                    return t('swaps.usage.table.values.finalized')
                  case 3:
                    return t('swaps.usage.table.values.cancelled')
                  default:
                    return null
                }
              },
            },
            {
              title: t('swaps.usage.table.columns.country'),
              field: 'country',
            },
            {
              title: t('swaps.usage.table.columns.swapType'),
              tooltip: t('swaps.usage.table.columns.swapType'),
              lookup: {
                customerReserve: t('swaps.usage.labels.customerReserve'),
                manualSwap: t('swaps.usage.labels.manualSwap'),
                autoswap: t('swaps.usage.labels.autoswap'),
              },
              customFilterAndSearch: (term, rowData) => {
                return (
                  (rowData.swapType === 0 &&
                    term.includes('customerReserve')) ||
                  (rowData.swapType === 1 && term.includes('manualSwap')) ||
                  (rowData.swapType === 2 && term.includes('autoswap')) ||
                  term.length === 0
                )
              },
              render: (rowData) => {
                switch (rowData.swapType) {
                  case 0:
                    return t('swaps.usage.table.values.customerReserve')
                  case 1:
                    return t('swaps.usage.table.values.manualSwap')
                  case 2:
                    return t('swaps.usage.table.values.autoswap')
                  default:
                    return null
                }
              },
            },
            {
              title: t('swaps.usage.table.columns.chargeStation'),
              field: 'stationLongName',
              render: (rowData) => displayWithTooltip(rowData.stationLongName),
            },
            {
              title: t('swaps.usage.table.columns.row'),
              field: 'rowLetter',
            },
            {
              title: t('swaps.usage.table.columns.operator'),
              field: 'operatorName',
              render: (rowData) => displayWithTooltip(rowData.operatorName),
            },
            {
              title: t('swaps.usage.table.columns.customer'),
              tooltip: t('swaps.usage.table.columns.customerTooltip'),
              field: 'customerId',
              render: (rowData) => displayWithTooltip(rowData.customerId),
            },
            {
              title: t('swaps.usage.table.columns.idBatteryIn'),
              tooltip: t('swaps.usage.table.columns.idBatteryInTooltip'),
              field: 'insertedBatteryPackId',
            },
            {
              title: t('swaps.usage.table.columns.idBatteriOut'),
              tooltip: t('swaps.usage.table.columns.idBatteriOutTooltip'),
              field: 'extractedBatteryPackId',
            },
            {
              title: t('swaps.usage.table.columns.socIN'),
              field: 'insertedBatterySoc',
              customFilterAndSearch: (term, rowData) =>
                String(rowData?.insertedBatterySoc).includes(term),
            },
            {
              title: t('swaps.usage.table.columns.socOUT'),
              field: 'extractedBatterySoc',
              customFilterAndSearch: (term, rowData) =>
                String(rowData?.extractedBatterySoc).includes(term),
            },
          ]}
          data={swapsReducer.swaps}
        />
      </div>

      <SwapDetails
        swapsReducer={swapsReducer}
        isOpen={isSwapDetailsOpen}
        onClose={() => setIsSwapDetailsOpen(false)}
      />

      <Create
        isOpen={isDialogCreateOpen}
        onClose={() => setIsDialogCreateOpen(false)}
      />

      <CreateSwapManual
        isOpen={isDialogCreateManualOpen}
        selectedItem={selectedManualSwapItem}
        resultItem={resultManualSwapItemSwap}
        onClose={() => setIsDialogCreateManualOpen(false)}
        getActiveManualSwapsForLogged={getActiveManualSwapsForLogged}
      />
    </React.Fragment>
  )
}
function mapState(state) {
  const { swapsReducer } = state
  return { swapsReducer }
}

const actionCreators = {
  getAll: swapsActions.getAll,
  getAllZones: zonesActions.getAllZones,
  getAllStations: stationsActions.getAllStations,
  getAllOperators: operatorActions.getAllOperators,
  getForTechnician: operatorActions.getForTechnician,
  getForLogged: stationsActions.getForLogged,
  getActiveManualSwapsForLogged: incidentsActions.getActiveManualSwapsForLogged,
}

export default connect(mapState, actionCreators)(SwapSection)
